// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// turbo links tem que ficar comentado porque senao esse javascript nao executa quando um link é clicado
// require("turbolinks").start()
require("@rails/activestorage").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

require("jquery.maskedinput/src/jquery.maskedinput")

//mesma coisa de ready
$(function () {

    // acionamento botões de acessibilidade 
    // aciona e retira autocontraste
    if (localStorage.getItem('autocontrasterevisa')) {
        $('body').addClass('auto-contraste');
        $('.imagem-goias-casa').addClass('esconde-componente');
        $('.imagem-goias-casa-pb').removeClass('esconde-componente');
    } else {
        $('body').removeClass('auto-contraste');
        $('.imagem-goias-casa-pb').addClass('esconde-componente');
        $('.imagem-goias-casa').removeClass('esconde-componente');
    }

    $(".modo-noturno").click(aplicarContraste);

    var alteracao_fonte = localStorage.getItem('tamanhoFonteRevisa');
    if (alteracao_fonte != null && alteracao_fonte != undefined) {
        alteracao_fonte = parseFloat(alteracao_fonte);
    } else {
        alteracao_fonte = 0;
    }

    aplicarFormatacao(1.12 + alteracao_fonte)

    // aumenta a fonte
    $(".maior").click(aumentarFonte);

    // restaura o tamanho da fonte
    $(".medio").click(restaurarFonte);

    // diminui fonte
    $(".menor").click(diminuirFonte);

    function aplicarContraste() {
        $('body').toggleClass('auto-contraste');
        $('.imagem-goias-casa,.imagem-goias-casa-pb').toggleClass('esconde-componente');
        armazenarContraste();
    }

    function aumentarFonte() {
        if (alteracao_fonte < 0.6) {
            alteracao_fonte = alteracao_fonte + 0.2;
            armazenarAlteracaoFonte(alteracao_fonte)
            aplicarFormatacao(1.12 + alteracao_fonte)
        }
    }

    function diminuirFonte() {
        if (alteracao_fonte > -0.4) {
            alteracao_fonte = alteracao_fonte - 0.2;
            armazenarAlteracaoFonte(alteracao_fonte)
            aplicarFormatacao(1.12 + alteracao_fonte)
        }
    }

    function restaurarFonte() {
        alteracao_fonte = 0;
        $('html').css("font-size", "100%")
        const xsize = 1.12;
        armazenarAlteracaoFonte(alteracao_fonte)
        aplicarFormatacao(xsize)
    }

    function aplicarFormatacao(tamanhoFonte) {
        const incremento = tamanhoFonte - 1.12;
        if(incremento >= 0.39) {
            $('#grupo2-centro').addClass('grupo-cads');
        } else {
            $('#grupo2-centro').removeClass('grupo-cads');
        }
        $('html').css("font-size", tamanhoFonte + "rem")
    }

    function armazenarAlteracaoFonte(tamanho) {
        localStorage.setItem("tamanhoFonteRevisa", tamanho.toString());
    }

    function armazenarContraste() {
        if (localStorage.getItem('autocontrasterevisa')) {
            localStorage.removeItem('autocontrasterevisa')
        } else {
            localStorage.setItem('autocontrasterevisa', 'true')
        }
    }
});

$(function () {
    $('#sugestao_telefone').mask("(99) 9999?9-9999")
});


//gambiarra que transforma o modal em modal de verdade
$(function () {
    var modal = $('.modal');
    if(modal && modal.length > 0) {
        $('.modal .close').click(function () {
            modal.hide();
        });

        document.addEventListener('click', function(e){  
            if (!$.contains(modal[0], e.target)){
              modal.hide();
            }
        });
    }
})